<template>
   <a-modal v-model:visible="visible" width="1190px" wrapClassName="aa" :footer="null" :title="title" @cancel="handleCancel">
      <section class="container">
          <article class="head">
            <div class="right">
                  <img :src="detail.url" alt="暂无图片">
              </div>
              <div class="left">
                <p style="font-size:20px;border-bottom: 1px solid #e6e6e6;width:100%;color:#474747;font-family: Microsoft Yahei">{{detail.name}}</p>
                <p style="font-size:16px;font-weight:500;width:100%;margin-top: 20px;">价  格：{{detail.jg}}</p>
              </div>
              
          </article>

          <div style="display: flex;justify-content: center;" v-for="item in list" :key="item">
            <figure v-for="i in item.t" :key="i">
                  <img class="pic"  :src="i.url" @click="changeItem(i)" >
              </figure>
            
          </div>
          
      </section>
   </a-modal>
</template>

<script>
import { reactive, toRefs} from '@vue/reactivity'
import {defineComponent} from 'vue'
import { state04} from '../assets/data/index.js'


export default defineComponent({
    name:'detail',
    setup(){
        const state = reactive({
            visible:false,
            title:'',
            detail:{},
            list:state04,
        })

        const showConter = function(e){
            state.visible = true
            state.detail = e.d
            console.log(state.detail);
        }

        const changeItem = function(e){
          console.log(e)
          state.detail = e.d
        }
        return{
            ...toRefs(state),
            showConter,
            changeItem
        }
    }
})
</script>

<style lang='stylus' scoped>
.container
  width 800
  p
    margin 0
.head
  display flex
  padding: 10%
  .right 
    display flex
    flex-direction column
    align-items flex-start
    justify-content space-between
    img 
      width 2.0rem 
      height 2.0rem
      display block
      background #f6f6f6
  .left 
    display flex
    flex-direction column
    align-items flex-start
    margin-left: 60px
    width:100%


.pic
    width: 1.0rem
    height: 1.0rem
    margin-right: 10px
.pic:hover
      cursor pointer
</style>