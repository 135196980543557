<template>
  <div class="container">
    <Header :currentTab="HeaderCurrentTab" @tabclickevent="HeaderTabClick" />
    <Swiper @E_product="toProduct" />
    <PrdouctsNew @E_detail="showDetail" /> 
    <!-- <Classic /> -->
    <!-- <PartnerNew /> -->
    <About />
    <ProfessionInfo />
    <!-- <button @click="add()">Store add + 1</button>
    <button @click="logInject()">Route to test.vue</button> -->
    <!-- <p>{{testState.count}}</p> -->
    <FooterNew />

    <Detail ref="detail" />
  </div>
</template>

<script>
// @ components
import Header from '@/components/Header.vue'
import FooterNew from '@/components/FooterNew.vue'
import Swiper from '@/components/Swiper.vue'
import About from '@/components/About.vue'
import ProfessionInfo from '@/components/ProfessionInfo.vue'
import Detail from '@/components/DetailNew.vue'
import PrdouctsNew from '@/components/PrdouctsNew.vue'
// hooks
import pageroll from '@/hooks/pageroll.js'

import Store from '../store';
import { addCount } from '../store/setter'
import { onMounted, reactive , ref, toRefs , inject } from 'vue';
// import router from '../router'
import { useRouter } from "vue-router";
export default {
  name: 'index',
  // components: {
  //   Header,Footer,Swiper,Prdoucts,Classic,Partner,About,
  //   Detail
  // },
  components: {
    Header,FooterNew,Swiper,ProfessionInfo,About,PrdouctsNew,Detail
  },
  setup(){
    const {RollTo,HeaderCurrentTab} = pageroll() // 页面滚动和选中
    const router = useRouter()
    const state = reactive({
      ...inject(Store.testStore),
    })
    // Header
    function HeaderTabClick(e){
      HeaderCurrentTab.value = e
      RollTo(e)
    }
    // Prdoucts
    const detail = ref()
    function showDetail(e){
      console.log(e)
      detail.value.showConter(e)
    }

    function toProduct(e){
      console.log(e.index)
      router.push({
        path: '/prodetail',
        name: 'ProDetail',
        query:e
      })
    }
    

    onMounted(async ()=>{

    }) 

    function logInject(){
      state.testState.count ++ 
      // state.test.count ++ 
    }

    async function add(){
      addCount()
      
      console.group(`store映射测试数据:`)
      console.log(state.testState)
      console.groupEnd()
    }

    const handleCancel = (e) => {
      console.log(e);
      state.visible = false;
    };
    return { 
      ...toRefs(state) ,
      HeaderCurrentTab,
      // Header
      HeaderTabClick,
      add,
      // Prdoucts
      detail,
      showDetail,
      toProduct,

      handleCancel,
      logInject
    }
  }
}
</script>

<style lang="stylus" scoped>
.container
  min-height 100vh
  position relative
  display block
  box-sizing border-box

</style>
