<template>
  <!-- <a-affix :offset-top="top"> -->
  <!-- <section id="Header">
      <article class="left">
          <img class="logo" src="../assets/image/index/wholler_logo.png" alt="">
          <div class="tip">
            <p style="margin:0;transform:scale(1);transform-origin:left">中国新零售和智能自助服务行业领导者</p>
            <p style="margin:0;transform:scale(1);transform-origin:left">创立于二O一七年</p>
          </div>
      </article>
      <article class="right">
          <div @click="tabClick(item.code)" v-for="(item,index) in tabs" :key="index" :class="{activeTab:item.code==currentTab}" class="tab">{{item.text}}</div>
          <p @click="phoneClick" class="phone">{{phone}}</p>
      </article>
  </section> -->
  <section id="Header" style="display:flex;align-items:center">
  <div style="display:flex;justify-content:space-between;padding: 0;width: 1190px;">
    <div>
      <img class="logo" src="../assets/image/index/wholler_logo.png" alt="">
    </div>
    <div style="display: flex;align-items:center;margin-right:57px">
      <img src="../assets/image/about/phone.png" width="52" height="39" alt="">
      <div @click="phoneClick" style="color:#C40000;font-size:23px;margin-left: 10px;font-weight: bold;" >{{phone}}</div>
    </div>
  </div>
  <div style="display:flex;justify-content:center;width:100%;background: #2b2b2b;height: 51px;">
    <div style="height:51px;width:1190px;display: flex;">
      <div style="background:#C40000;width:240px;display: flex;align-items: center;color:white;margin-right: 30px;font-size: 16px;">
        <img src="../assets/image/index/lieb.png" width="20" height="20" style="margin-left:10%"/>
        <span style="margin-left:20%">产品分类</span>
      </div>
      <div @click="tabClick(item.code)" v-for="(item,index) in tabs" :key="index" :class="{activeTab:item.code==currentTab}" class="fenl">{{item.text}}</div>
    </div>
  </div>
  
</section>
  <!-- <div style="padding: 0 20%;display:flex">
    <div style="width:150px;background: #f5f5f5;">
      <div class="product">智能售货机</div>
      <div class="product">多功能自助机</div>
      <div class="product">物联网设备</div>
      <div class="product">格子柜</div>
      <div class="product">取餐机</div>
      <div class="product">广告机</div>
      <div class="product">消费类电子</div>
    </div>
  </div> -->
  <!-- </a-affix> -->
</template>

<script>
import { toRefs, reactive } from '@vue/reactivity'

export default {
  name: 'Header',
  props: {
    currentTab:String,
  },
  setup(props,context){
    const state = reactive({
      tabs:[
        {
          text:"首 页",
          code:"Header"
        },
        {
          text:"产品展示",
          code:"PrdouctsNew"
        },
        {
          text:"关于我们",
          code:"About"
        },
        {
          text:"新闻动态",
          code:"ProfessionInfo"
        },
        {
          text:"联系我们",
          code:"FooterNew"
        }
      ],
      phone:'400-8381-708'
    })
    const tabClick = function(e){
      console.log(props)
      context.emit('tabclickevent',e)
    }
    const phoneClick = function(){

    }
    return {
      ...toRefs(state),
      tabClick,
      phoneClick
    }
  }
}
</script>

<style scoped lang="stylus">
#Header
  background #fff
  display flex
  flex-direction: column
.left 
  padding 0 .6rem
  height 100%
  display flex
  transform scale(1rem)
  align-items center
.logo
  height 110px
  min-height 48px
  width 200px
  margin-right .1rem 
.tip
  padding-left .1rem
  font-size .1rem
  height .3rem
  min-height 36px
  border-left 1px solid #999
  color #666
  display flex
  font-family Georgia, 'Times New Roman', Times, serif
  flex-direction column
  align-items flex-start
  justify-content space-between
.right
  font-size .1rem
  flex 1
  height 100%
  display flex
  align-items center
  padding .16rem 0 0 0.24rem
  font-family Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
  .tab
    padding 0.01rem .08rem
    white-space nowrap
    border-radius .01rem
  .tab:hover{
    background blue
    color #fff
    border-radius .01rem
    cursor pointer
  }
  .activeTab
    background blue
    color #fff
    border-radius .01rem
  .phone
    color red 
    font-size .2rem
  .phone:hover
    color blue
    text-decoration underline
.fenl
    color white
    padding 10px 20px
    display flex
    justify-content: center
    align-items: center
    font-size: 16px
    width: 120px
.fenl:hover
    background #C40000
    cursor: pointer
.product
  color: black
  height: 50px
  display: flex
  align-items: center
  padding-left: 10px
  font-size: 12px
.product:hover
  color: red
  background: white
  cursor: pointer
</style>
