<template>
  <section id="FooterNew">
    <div style="width:1190px;display: flex;align-items: center;justify-content: space-between;">
      <div>
        <div style="display:flex;align-items:center;font-size: 16px;">联系我们：<div style="color:#C40000;font-size: 22px;">
            400-8381-708</div>
        </div>
        <div>厦门市翔安区翔虹路18号二楼</div>
      </div>
      <div style="width:1px;height:96px;background:#333"></div>
      <div>
        <div>联系QQ：10941782</div>
        <div>手机号码：13400611359</div>
        <div>联系邮箱：lhj@wholler.com</div>
      </div>
      <div style="width:1px;height:96px;background:#333"></div>
      <div>
        <div>@2024-互隆科技 版权所有</div>
        <div>{{ nicp }}</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterNew',
  props: {
    // msg: Number
  },
  data(){
    return{
      nicp:'',
    }
  },
  created() {
    let icp = "闽ICP备18018581号-1"
    let domain = window.location.href;
    console.log(domain)
    // if(domain == "dishanapp.cn" || domain == "www.dishanapp.cn"){
    //   icp = "闽ICP备18018581号-2"
    // }
    // else if(domain == "dietinternet.cn" || domain == "www.dietinternet.cn"){
    //   icp = "闽ICP备18018581号-3"
    // }
    // else if(domain == "wholler.cn" || domain == "www.wholler.cn"){
    //   icp = "闽ICP备18018581号-4"
    // }
    // else if(domain == "dietinternet.com.cn" || domain == "www.dietinternet.com.cn"){
    //   icp = "闽ICP备18018581号-5"
    // }
    // else if(domain == "wholler.com" || domain == "wholler.com"){
    //   icp = "闽ICP备18018581号-6"
    // }
    // else if(domain == "localhost" || domain == "127.0.0.1"){
    //   icp = "闽ICP备18018581号"
    // }
    if(-1 < domain.indexOf("dishanapp.cn")){
      icp = "闽ICP备18018581号-2"
    }
    else if(-1 < domain.indexOf("dietinternet.cn")){
      icp = "闽ICP备18018581号-3"
    }
    else if(-1 < domain.indexOf("wholler.cn")){
      icp = "闽ICP备18018581号-4"
    }
    else if(-1 < domain.indexOf("dietinternet.com.cn")){
      icp = "闽ICP备18018581号-5"
    }
    else if(-1 < domain.indexOf("wholler.com")){
      icp = "闽ICP备18018581号-6"
    }
    else if(-1 < domain.indexOf("localhost")){
      icp = "闽ICP备18018581号"
    }
    this.nicp = icp
  },
  
}
</script>

<style lang='stylus' scoped>
#FooterNew
  width 100%
  background #000
  display flex
  justify-content center
  height: 205px
  color: white
  font-size: 14px

</style>