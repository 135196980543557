<template>
  <section id="ProfessionInfo">
    <div style="display: flex;width: 1190px;padding-bottom: 60px;">
      <div style="width:425px">
        <div style="display:flex;justify-content:space-between;align-items: center;margin-bottom: 10px;margin-left: 5px;">
          <span style="font-size:22px;color:#c40000;">行业资讯</span>
          <span style="font-size:12px;color:#c40000">MORE</span>
        </div>
        <div class="info" >
          <span>物联网应用不断拓展广度深度</span>
          <span>2023-09-20</span>
        </div>
        <div class="info" >
          <span>物联网赋能制造业生产</span>
          <span>2023-08-16</span>
        </div>
        <div class="info" >
          <span>万物相连 今年我国物联网连接数预计将超23亿</span>
          <span>2023-04-20</span>
        </div>
        <div class="info" >
          <span>工信部：我国移动物联网连接数全球占比超70%</span>
          <span>2022-09-13</span>
        </div>
        <div class="info" >
          <span>电力物联网加速创新 数据互联互通释放强劲动能</span>
          <span>2022-04-11</span>
        </div>
      </div>
      <div style="display:flex;padding-left:80px;padding-top: 40px;align-items: center;width: 775px;">
        <img src="../assets/image/about/info_1.jpg" width="245" height="295" alt="">
        <div style="padding: 30px 0 30px 30px;">
          <div style="font-size:18px;color: #343434;">物联网应用不断拓展广度深度</div>
          <div style="font-size:14px;margin-top: 10px;color: #787878;;line-height: 35px;">物联网是战略性新兴产业的重要组成部分。近年来，我们大力推进新型基础设施建设，以智能制造为主攻方向，推动我国物联网蓬勃发展。</div>
          <button class="look"  @click="lookdetail">查看详情</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup(){
    const lookdetail = function(){
      console.log("lookdetail")
    }
    return {
      lookdetail
    }
  }
}
</script>

<!-- height 10rem -->
<style lang='stylus' scoped>

#ProfessionInfo
  width 100%
  font-size: 12px
  display: flex
  justify-content: center

.info
  display:flex
  justify-content:space-between
  height: 70px
  align-items: center 
  font-size: 14px
  margin-left: 10px
.info:hover
  cursor pointer
  color #C40000
.look
  margin-top: 25px
  border: solid 1px #C40000
  color: #C40000
  background: white
  width: 120px
  display: flex
  justify-content: center
  align-items: center
  height: 40px
  font-size: 14px
  font-weight: bold
.look:hover
  cursor pointer
</style>